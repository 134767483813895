.main-content.guest {
	background-image: url('../resources/images/admatic-bg.jpg');
	min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
}

.main-content.guest.guaranteed-irish {
	/*background: linear-gradient(to bottom left, #3DB549, #2B6E33, #244E28);*/
	background-image: url('../resources/images/gi-bg.webp');
	min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
}

.admatic-logo {
	width:  200px;
}

.makeStyles-logoClasses-16, .jss16 {
	max-height: 4.5rem !important;
}


.full-page-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.large-icon {
  	font-size: '50px' !important;
}

.add-logo-dropzone {
	width: 250px;
	margin:  0 auto;
}

.category-card {
	cursor:  pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.category-card:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.category-card p {
	margin: 0;
	font-size: 1.5rem;
}

.MuiPickersCalendarHeader-transitionContainer {
	overflow: unset !important;
}

.bold-text {
	font-weight: 700;
}

.hover-text {
	cursor:  pointer;
}

.hover-text:hover {
	text-decoration: underline;
}

.MuiOutlinedInput-input.Mui-disabled {
    background-color: #e9ecef;
}

.dropzone {
	text-align: center;
	cursor:  pointer;
}

.category-icon {
	font-size: 2.5rem !important;
	width:  2.5rem !important;
	height:  2.5rem !important;
}

.register-icon {
	font-size: 3rem !important;
	width:  3rem !important;
	height:  3rem !important;
	color:  #2dce89;
}

.image-modal-img {
	max-width: 100%;
}


/* home */

.home-check-icon {
	font-size: 2rem !important;
	width:  2rem !important;
	height:  2rem !important;
	color:  #fff;
	margin-right: 0.5rem;
}

.home-logo {
	width:  300px;
}

/*.MuiBox-root-107 {
	position: initial !important;
}*/

.hover-text {
	cursor: pointer;
}

.payment-status {
	color: #5e72e4;
}

.option-box {
	border: 1px solid #eee;
	padding: 4px;
	border-radius: 2px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.gi-logo {
	width: 350px;
	border-radius: 10px;
	margin-bottom: 1rem;
}

.buyAndBookTa{
	background-color: #fff;
}
